import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Slider from 'react-slick';
import { v4 as uuidv4 } from 'uuid';
import './App.css';

function App() {
  const [isListening, setIsListening] = useState(false);
  const [microphoneAnimation, setMicrophoneAnimation] = useState('');
  const [dialogArray, setDialogArray] = useState([]);
  const [sessionId, setSessionId] = useState('');
  const [responseType, setResponseType] = useState('chat');
  const [data, setData] = useState(null);
  const [waitAnswer, setWaitAnswer] = useState(false);
  const dialogEndRef = useRef(null);
  const [currentlyPlayingAudio, setCurrentlyPlayingAudio] = useState(null);

  useEffect(() => {
    dialogEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [dialogArray]);

  useEffect(() => {
    const newSessionId = uuidv4();
    setSessionId(newSessionId);
  }, []);

  const startListening = () => {
    if (currentlyPlayingAudio) {
      currentlyPlayingAudio.pause();
      setCurrentlyPlayingAudio(null);
    }

    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    if (!SpeechRecognition) {
      alert('Speech recognition not supported');
      return;
    }

    const recognition = new SpeechRecognition();
    recognition.lang = 'ru-RU'; //!!!
    recognition.interimResults = true;

    recognition.onerror = (error) => {
      console.error('Speech recognition error', error);
    };

    recognition.onstart = () => {
      setIsListening(true);
      setMicrophoneAnimation('animate-listening');
    };

    recognition.onresult = async (event) => {
      let transcript = '';
      for (const result of event.results) {
        transcript += result[0].transcript;
        if (result.isFinal) {
          // Handle final result
          console.log('Final transcript:', transcript);

          // Make an Axios request to the backend with the recognized text
          try {
            setDialogArray((prevArray) => [
              ...prevArray,
              transcript,
            ]);
            setWaitAnswer(true);

            await new Promise(resolve => setTimeout(resolve, 1000));

            const response = await axios.post('/api/response', {
              prompt: transcript,
              session_id: sessionId,
          });

          console.log('Response:', response.data);

          //Вот такой ответ должен быть, цены тоже после того как партнерку подключим

            // const response = {
            //   data: {
            //     response_type: 'suggestion',
            //     Suggestions: {
            //       "persons": 2,
            //       "children": 0,
            //       "hotels": [
            //         {
            //           "stars": 5,
            //           "image": "img/hotel.jpg",
            //           "price": "1 500",
            //           "currency": "USD",
            //           "name": "1. Amara Dolce Vita Luxury Resort",
            //           "description": "5-star hotel located on the beachfront with multiple swimming pools, spa facilities, and a variety of restaurants offering different cuisines.",
  
            //           "check_in": "2023-12-20",
            //           "check_out": "2023-12-27"
  
            //         },
            //         {
            //           "stars": 3,
            //           "image": "img/hotel.jpg",
            //           "price": "1 500",
            //           "currency": "USD",
            //           "name": "2. Rixos Sungate",
            //           "description": "5-star all-inclusive resort with a private beach, waterpark, multiple pools, spa, and various dining options.",
            //           "check_in": "2023-12-20",
            //           "check_out": "2023-12-27"
            //         },
            //         {
            //           "stars": 4,
            //           "image": "img/hotel.jpg",
            //           "price": "1 500",
            //           "currency": "USD",
            //           "name": "3. Crystal De Luxe Resort & Spa",
            //           "description": "5-star beachfront hotel offering comfortable rooms, multiple swimming pools, spa facilities, and a choice of restaurants.",
            //           "check_in": "2023-12-20",
            //           "check_out": "2023-12-27",
            //         }
            //       ],
            //       "avia": [
            //         {
            //           "type": "roundtrip",
            //           "outbound_flight": {
            //             direct: true,
            //             "number": "LH789",
            //             "airline": "Lufthansa",
            //             "departure": {
            //               "airport": "SVO",
            //               "time": "2023-12-25T07:00:00"
            //             },
            //             "arrival": {
            //               "airport": "BER",
            //               "time": "2023-12-25T08:45:00"
            //             },
            //             "duration": "1h 45m"
            //           },
            //           "return_flight": {
            //             direct: true,
            //             "number": "LH790",
            //             "airline": "Lufthansa",
            //             "departure": {
            //               "airport": "BER",
            //               "time": "2024-01-05T19:00:00"
            //             },
            //             "arrival": {
            //               "airport": "SVO",
            //               "time": "2024-01-05T20:45:00"
            //             },
            //             "duration": "1h 45m"
            //           },
            //           "total_price": "1500.00",
            //           "currency": "USD",
            //           "booking_link": "https://www.aviasales.ru/search/SVOBER2512-0501"
            //         },
            //         {
            //           "type": "oneway",
            //           "outbound_flight": {
            //             direct: true,
            //             "number": "LH789",
            //             "airline": "Lufthansa",
            //             "departure": {
            //               "airport": "SVO",
            //               "time": "2023-12-25T07:00:00"
            //             },
            //             "arrival": {
            //               "airport": "BER",
            //               "time": "2023-12-25T08:45:00"
            //             },
            //             "duration": "1h 45m"
            //           },
            //           "total_price": "1500.00",
            //           "currency": "USD",
            //           "booking_link": "https://www.aviasales.ru/search/SVOBER2512-0501"
            //         },
            //         // {
            //         //   "type": "oneway",
            //         //   "outbound_flight": {
            //         //     direct: false,
            //         //     flights: [
            //         //       {
            //         //         "number": "LH789",
            //         //         "airline": "Lufthansa",
            //         //         "departure": {
            //         //           "airport": "SVO",
            //         //           "time": "2023-12-25T07:00:00"
            //         //         },
            //         //         "arrival": {
            //         //           "airport": "BER",
            //         //           "time": "2023-12-25T08:45:00"
            //         //         },
            //         //         "duration": "1h 45m"
            //         //       },
            //         //       {
            //         //         "number": "LH789",
            //         //         "airline": "Lufthansa",
            //         //         "departure": {
            //         //           "airport": "SVO",
            //         //           "time": "2023-12-25T07:00:00"
            //         //         },
            //         //         "arrival": {
            //         //           "airport": "BER",
            //         //           "time": "2023-12-25T08:45:00"
            //         //         },
            //         //         "duration": "1h 45m"
            //         //       }
            //         //     ]
            //         //   },
            //         //   "total_price": "1500.00",
            //         //   "currency": "USD",
            //         //   "booking_link": "https://www.aviasales.ru/search/SVOBER2512-0501"
            //         // }
            //       ]
            //     }
            //   }
         
            // }

          if (response.data.response_type === 'suggestion') {
            console.log('response:', response.data)
            console.log('Suggestion response:', response.data.response.suggestion)
            setData(response.data.response.suggestion);
            setResponseType('suggestion');
          } else {
            let answer = response.data.response.chat;
            if (answer.includes('\n')) {
              answer = answer.replace(/\n/g, '<br>');
            }          
            // Update the dialogArray state with the response from the backend
            setDialogArray((prevArray) => [
              ...prevArray,
              <div dangerouslySetInnerHTML={{ __html: answer }} />,
            ]);

            const audio = new Audio(`audio/${sessionId}.mp3?v=${Date.now()}`);
            audio.play();
            setCurrentlyPlayingAudio(audio);
          }

          } catch (error) {
            console.error('Error making Axios request:', error);
          }

          setWaitAnswer(false);
        }
      }
    };


    recognition.onend = () => {
      setIsListening(false);
      setMicrophoneAnimation('');

    };

    recognition.start();
  };

  const sliderSettings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const renderFlights = () => {
    if (data && data.flight) {
      let flights = [data.flight]; // !!! пока так, билетов будет несколько
      return flights.map((flight, index) => (
        <div key={`flight-${index}`} className="slide ticket" style={{ pointerEvents: 'all', position: 'relative', opacity: 1, transform: 'matrix(1, 0, 0, 1, 0, 0)' }}>
          <div className="info-outer">
            <div className="row">
              <div className="title">{flight.outbound_flight.departure.airport} <span>{flight.outbound_flight.departure.code}</span> - {flight.outbound_flight.arrival.airport} <span>{flight.outbound_flight.arrival.code}</span></div>
              <div className="date">{/* Дата вылета */}</div>
            </div>
  
            <div className="blocks-outer">
              <div className="block">
                <div className="icon-outer">
                  <img src="img/ticket_icon_1.png" alt="Icon" />
                </div>
                <div className="label">Вылет</div>
                <div className="value-1">{/* Дата вылета */}</div>
                <div className="value-2">{flight.outbound_flight.departure.time}</div>
              </div>
  
              <div className="block">
                <div className="icon-outer">
                  <img src="img/ticket_icon_2.png" alt="Icon" />
                </div>
                <div className="label">Прилет</div>
                <div className="value-1">{/* Дата прилета */}</div>
                <div className="value-2">{flight.outbound_flight.arrival.time}</div>
              </div>
  
              <div className="block">
                <div className="icon-outer">
                  <img src="img/ticket_icon_3.png" alt="Icon" />
                </div>
                <div className="label">Flight</div>
                <div className="value-1">{flight.outbound_flight.number}</div>
                <div className="value-2">{/* Тип самолета, если доступен */}</div>
              </div>
  
              <div className="block ticket-logo-block">
                <div className="ticket-logo-outer">
                  <img src="img/ticket_logo.png" alt="Logo" />
                </div>
                <div className="label">Авиакомпания</div>
                <div className="value-2">{flight.outbound_flight.airline}</div>
              </div>
            </div>
  
            <a className="buy-ticket" href={flight.booking_link}>Купить</a>
          </div>
        </div>
      ));
    } else if (data && data.hotel) {
      return <p>No flight data available.</p>;
    }
  };
  
  

  const renderHotelSlides = () => {
    if (data && data.hotels) {
      return data.hotels.map((hotel, index) => (
        <div key={`hotel-${index}`} className="slide slide-hotel">
          <div className="image-outer">
            <img src={hotel.image} alt={hotel.name} />
          </div>
  
          <div className="info-outer">
            <div className="row">
              <div className="title">
                {hotel.name}
                <div class="rating-outer">
                {Array.from({ length: hotel.stars }, (_, i) => (
                  <img src="img/star.png" alt="Star" />
                ))}
											</div>

              </div>
              <div className="slide-logo-outer">
                {/* Логотип отеля, если есть */}
              </div>
            </div>
  
            <div className="row">
              <div className="town-outer">
                <p className="label">Место:</p>
                {/* Город отеля, если есть */}
              </div>
  
              <div className="date-outer">
                <p className="label">Дата заезда\выезда:</p>
                <p className="value">с {hotel.check_in} по {hotel.check_out}</p>
              </div>
            </div>

            <div className="row">
              <div className="price-outer">
                <div className="label">{hotel.description}</div>
              </div>
            </div>
  
            <div className="row">
              <div className="price-outer">
                <div className="label">Цена за период:</div>
                <div className="price">от {hotel.price} {hotel.currency}</div>
              </div>
            </div>
  
            <a className="buy-hotel" href={hotel.booking_link} target="_blank" rel="noopener noreferrer">Купить</a>
          </div>
        </div>
      ));
    } else {
      return <p>No hotel data available.</p>;
    }
  };
  

  const [currentWord, setCurrentWord] = useState('авиабилетов');
  const [animation, setAnimation] = useState('word-animation-enter');
  const words = ['авиабилетов', 'отелей'];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setAnimation('word-animation-exit');
      setTimeout(() => {
        setCurrentWord(prevWord => (prevWord === words[0] ? words[1] : words[0]));
        setAnimation('word-animation-enter');
      }, 1000);
    }, 4000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <section>
      <div className="background"></div>
      <div className="logo-outer">
        <img className="logo" src="img/logo.png" alt="Logo" />
      </div>
      <div className="wrapper">
        <div className="title">
          <span>Поиск дешевых</span>

          <div id="words" className="words">
            <div className={animation}>
              <p>{currentWord}</p>
            </div>
          </div>

        </div>
        {responseType !== 'suggestion' && (
  <div id="microphone" className="microphone-outer">
    <div className="circles-outer">
      <div className="circles" id="circles">
        <div className="circle"></div>
        <div style={{ display: isListening ? 'block' : 'none' }} className="circle"></div>
        <div style={{ display: isListening ? 'block' : 'none' }} className="circle"></div>
        <div style={{ display: isListening ? 'block' : 'none' }} className="circle"></div>
        <div style={{ display: isListening ? 'block' : 'none' }} className="circle"></div>
      </div>
    </div>
    <img
  className={`microphone ${microphoneAnimation}`}
  src="img/microphone.png"
  alt="Microphone"
  onClick={startListening}
  onTouchStartCapture={startListening}
  onTouchStart={startListening}
  onTouchEnd={startListening}
  style={{ touchAction: 'manipulation' }}
/>


  </div>
)}

        {dialogArray.length === 0 && (
          <>
            <div className="step-1">
              <div className="tip">
                <span className="different">Просто скажите,</span> <br />
                <span>что вам нужно, будто общаетесь с тур-агентом,</span> <br />
                <span className="different">и получите результат</span>
              </div>
              <div className="links-outer">
                <div className="images">
                  <a href="#" target="_blank">
                    <img src="img/app_store.png" alt="App store" />
                  </a>
                  <a href="#" target="_blank">
                    <img src="img/google_play.png" alt="Google play" />
                  </a>
                </div>
                {/* <div className="additionals">
                  <p className="text">То же самое можно сделать в:</p>
                  <div className="links">
                    <a className="facebook" href="#" target="_blank">@facebook</a>
                    <a className="telegram" href="#" target="_blank">@telegram</a>
                  </div>
                </div> */}
              </div>
            </div>
          </>
        )}
        {dialogArray.length > 0 && responseType === 'chat' && (
          <>
            <div className="step-2">
              <div id="dialog" className="dialog-outer scrollable">
                <div className="scroll-bar vertical">
                  <div className="thumb" style={{ top: '0px', height: '328.033px' }}></div>
                </div>
                <div className="viewport" style={{ height: '280px', width: '290px' }}>
                  <div className="overview" style={{ top: '0px', left: '0px' }}>
                    {dialogArray.map((dialog, index) => (
                      <div key={index} className={(index + 1) % 2 == 0 ? 'answer' : 'question'} style={{ opacity: 1 }}>
                        {dialog}
                      </div>
                    ))}
                    <div ref={dialogEndRef} /> {/* Invisible element at the end of dialog */}
                  </div>
                </div>
                <div className="scroll-bar horizontal" style={{ width: '290px' }}>
                  <div className="thumb" style={{ left: '0px', width: '290px' }}></div>
                </div>
              </div>
            </div>
            {waitAnswer && dialogArray.length % 2 != 0 && (
              <div className="loader-outer">
                <div className="loader"></div>
              </div>


            )}
          </>
        )}
        {responseType === 'suggestion' && (
          <>
            <div className="step-3">
              <div className="request">
                <p className="text-1">Ваш запрос: "кратко о главном"</p>
              </div>
              <div className="result-text">Авиабилеты:</div>
              <div className="slider-outer">
              <div className="slides">

              <Slider {...sliderSettings}>
                {renderFlights()}
                </Slider>
                <div className="result-text" style={{marginTop: "30px"}}>Отели:</div>
                <Slider {...sliderSettings}>
                {renderHotelSlides()}
                </Slider>

              </div>
              </div>
            </div>
          </>
        )}

      </div>

      <div className="copyrights">
        <p>
          2023 © Papa.Travel&nbsp;
          <a href="#" target="_blank">Правила использования</a>
          <a href="#" target="_blank">Пользовательское соглашение</a>
        </p>
      </div>
    </section>
  );
}

export default App;
